





































































































































































import Vue from 'vue'
import { useTable } from '@/components/table/table-hook'
import RichTextEditor from '@/components/form/RichTextEditor.vue'
import SelectActivityType from '@/components/select/select-activity-type.vue'
import SelectDisCountType from '@/components/select/select-discount-type.vue'
import { getBannerList, editBanner, delBanner } from '@/api/activity_banner'
import { getActivityList } from '@/api/activity'
import { getOssUrl } from '@/api/common'
export default Vue.extend({
	components: {
		RichTextEditor,
		SelectActivityType,
		SelectDisCountType,
	},
	inject: ['oss179'],
	data() {
		return {
			baseUrl: '',
			oldBaseUrl: '',
			indexs: 0,
			que: [
				{ id: 1, name: '白银会员' },
				{ id: 2, name: '金卡会员' },
				{ id: 3, name: '铂金会员' },
				{ id: 4, name: '钻石会员' },
				{ id: 5, name: '永久钻石会员' },
			],

			editBanner,
			...useTable({
				name: '活动',
				formdata: {
					/** 名称 */
					id: '',
					name: '',
					cover_image: '',
					detail: '',
					activity_id: '',
					platform: 0,
					mp: 0,
					type: 1,
					banner_type: 1,
					mp_link: '',
					url: '',
					skip_url: '',
				},

				getData: (query) => getBannerList(query).then((res) => res.data),
				delData: (row) => delBanner({ id: row.id }),
			}),
			options: [] as any,
			checkList: [] as any,
			menuList: [] as any,
			current: '' as any,
			arrhuodong: [] as any,
		}
	},
	// watch: {
	// 	'table_formdata.type': {
	// 		immediate: true,
	// 		handler(val) {
	// 			console.log(val)
	// 			if (new Date().getTime() - this.indexs > 1000) {
	// 				if (val != 1) {
	// 					this.table_formdata.activity_id = ''
	// 				}
	// 			}
	// 		},
	// 	},
	// },
	created() {
		this.getOssUrl()
		this.tableGetData()
		this.getadd()
	},
	methods: {
		switchtype(e) {
			if (e == 1) {
				this.table_formdata.activity_id = ''
				this.table_formdata.skip_url = ''
			}
		},
		activityclick(e) {
			this.table_formdata.skip_url = ''
		},
		skip() {
			// 内部跳转活动和内联二选其一
			this.table_formdata.activity_id = ''
		},
		arrchange(e) {},
		getadd() {
			getActivityList().then((res) => {
				// console.log(res.data.rows)
				this.arrhuodong = res.data.rows
				// console.log(this.arrhuodong);
				this.arrhuodong.map((res) => {
					this.options.push({
						value: res.id,
						label: res.name,
					})
				})
			})
		},
		openpermi(row) {
			this.checkList = row.menu_ids
			this.current = row
		},
		savePermi() {},
		getOssUrl() {
			getOssUrl().then((res) => {
				this.baseUrl = res.data.domain
				this.oldBaseUrl = res.data.old_domain
			})
		},
		onSubmit(row) {
			row.vip_card_ids = JSON.stringify(row.vip_card_ids)
			row.few_fold = row.few_fold / 10
			row.full = row.full * 100
			row.reduction = row.reduction * 100

			let base_url_length = this.baseUrl.split('').length
			if (row.cover_image.indexOf(this.baseUrl) !== -1) {
				row.cover_image = row.cover_image.slice(base_url_length)
			}

			console.log(row)

			// 如果 row.type 不存在，则将其设置为 1
			if (!row.type) {
				row.type = 1
			}

			// 如果 row.activity_id 不为 0 或不为空字符串，或者 row.mp 为 0，则清空 row.mp_link
			if (row.activity_id != 0 || row.activity_id != '' || row.mp == 0) {
				row.mp_link = ''
			}

			// 如果 row.banner_type 为 1，则清空 row.mp_link 和 row.url
			if (row.banner_type == 1) {
				row.mp_link = ''
				row.url = ''
				// 否则，清空 row.activity_id 和 row.skip_url
			} else {
				row.activity_id = ''
				row.skip_url = ''
			}
			console.log(1111, row)
			// 如果 row.activity_id 大于 0，则将 row.type 设置为 1
			if (row.activity_id > 0 || row.skip_url != '') {
				row.type = 1
			}
			// 外部链接&& 网页地址
			if (row.mp == 0 && row.banner_type == 2) {
				// 且 row.url 为空，则提示用户输入链接地址并返回 false
				if (row.url == '') {
					row.type = 1
				}
			}
			if (row.mp == 1 && row.banner_type == 2) {
				// 且 row.mp_link 和 row.url 都为空，则提示用户输入链接地址并返回 false
				if (row.mp_link == '' && row.url == '') {
					row.type = 1
				}
			}
			// 调用 editBanner 方法更新数据，成功后提示保存成功并刷新表格数据
			return editBanner(row).then(() => this.$message.success('保存成功') && this.tableGetData())
		},
		editrow(row) {
			row = JSON.parse(JSON.stringify(row))
			if (row.activity_id == 0) {
				row.activity_id = ''
			}
			row.few_fold = row.few_fold * 10
			row.full = row.full / 100
			row.discount = row.discount * 10
			row.mp = row.mp * 1
			this.table_formdata = row
			if (row.activity_id > 0 || row.skip_url != '') {
				row.type = 2
			}
			if ((row.activity_id < 0 || row.activity_id == '') && row.skip_url == '' && row.banner_type != 2) {
				row.type = 1
			}
			;(this as any).$refs.formDialogRef.open(row)
		},
	},
})
