

















































































import Vue from 'vue'
import { useTable } from '@/components/table/table-hook'
import { getVipOrderList, delmemder } from '@/api/hotel-label'
import SelectVipLv from '@/components/select/select-vip-lv.vue'
export default Vue.extend({
	components: { SelectVipLv },
	data() {
		return {
			dialogVisible: false,
			uid: 0,
			...useTable({
				name: '',
				formdata: {
					/** ID */
					id: 0,
					/** 名称 */
					name: '',
				},
				getData: (query) => getVipOrderList(query).then((res) => res.data),
			}),
		}
	},
	created() {
		this.tableGetData()
	},
	methods: {
		tuikuan(id: any) {
			this.dialogVisible = true

			this.uid = id
		},
		decodeUnicode(str) {
			str = str.replace(/\\/g, '%')
			// console.log(str, 'str')
			return unescape(str)
		},
		queren() {
			let arrfor = {
				id: this.uid,
			}
			delmemder(arrfor).then((res) => {
				console.log('====================================')
				console.log(res)
				console.log('====================================')
				this.tableGetData()
				this.dialogVisible = false
			})
		},
	},
})
