import request, { ResponsePageableData } from '@/libs/request'

//获取活动列表
export const getBannerList = (data?) => request<ResponsePageableData>({ url: '/adminapi/Banner/getBannerList', data })

//编辑活动
export const editBanner = (data?) => request<ResponsePageableData>({ url: '/adminapi/Banner/editBanner', data })

//删除活动
export const delBanner = (data?) => request<ResponsePageableData>({ url: '/adminapi/Banner/delBanner', data })
