












































































import Vue from 'vue'
import { useTable } from '@/components/table/table-hook'
import { getUserAuthList, editUserAuth, resettingUserAuth } from '@/api/hotel-label'
import SelectTypeLabel from '@/components/select/select-type-label.vue'
import SelectUseao from '@/components/select/select-ao-usue.vue'
export default Vue.extend({
	components: { SelectTypeLabel, SelectUseao },
	inject: ['oss140'],
	data() {
		return {
			state_type: 0,
			dialogVisible: false,
			chongzhi: false,
			uid: 0,
			title: '',
			...useTable({
				name: '',
				formdata: {
					/** ID */
					id: 0,
					/** 名称 */
					name: '',
				},
				getData: (query) => getUserAuthList(query).then((res) => res.data),
			}),
			lsiy: ['', '中国大陆身份证', '港澳居民来往内地通行证', '台湾居民来往大陆通行证', '港澳居民居住证', '台湾居民居住证', '华侨护照', '军官证'],
			status: ['', '未认证', '待审核', '已通过', '已驳回'],
		}
	},
	created() {
		this.tableGetData()
	},
	methods: {
		tuikuan(id: any, typr: string) {
			if (typr == '审核') {
				this.title = '确认通过审核吗？'
				this.state_type = 3
			} else if (typr == '驳回') {
				this.title = '确认驳回吗？'
				this.state_type = 4
			} else if (typr == '重置') {
				this.title = '确认重置吗？'
				this.state_type = 5
			}
			this.dialogVisible = true
			this.uid = id
		},
		decodeUnicode(str) {
			str = str.replace(/\\/g, '%')
			// console.log(str, 'str')
			return unescape(str)
		},
		queren() {
			let arrfor = {
				id: this.uid,
				status: this.state_type,
			}
			if (this.state_type == 5) {
				resettingUserAuth({ id: this.uid }).then((res) => {
					console.log(res)

					this.$message({
						message: '重置成功！',
						type: 'success',
					})
					// this.chongzhi = ture;
					this.tableGetData()
					this.dialogVisible = false
				})
			} else {
				editUserAuth(arrfor).then((res) => {
					this.$message({
						message: '操作成功！',
						type: 'success',
					})
					this.tableGetData()
					this.dialogVisible = false
				})
			}
		},
	},
})
